import React, { useEffect } from 'react';

import { FeatureToggles } from '@paralleldrive/react-feature-toggles';

import Alerts from '@components/Alerts';
import Footer from '@components/Footer';
import Header from '@components/Header';
import { titleTemplateParams } from '@components/Title/util';
import ImpersonationHeader from '@components/User/ImpersonationHeader';

import { NOTIFICATIONS_WRAPPER_ID } from '@domain/notifications';

import ApolloCustomProvider from '@utils/clients';
import {
  enabledFeatures,
  FEATURE_SITECORE_FOOTER,
  FEATURE_SITECORE_HEADER,
  isEnabled,
} from '@utils/features';
import { delocalizedPath } from '@utils/localized-path';

import SitecoreFooter from '@sitecore/wrappers/SitecoreFooter';
import SitecoreHeader from '@sitecore/wrappers/SitecoreHeader';
import SitecoreLayout from 'src/domui/layouts';

import { Helmet, HelmetProvider } from '@external/react-helmet-async';
import { useTranslation } from '@external/react-i18next';
import { useAnalytics } from '@hooks/analytics';
import AppConfigProvider from '@hooks/appConfig';
import ErrorBoundary from '@hooks/error-boundary';

const Layout: React.FC<{
  location: Location;

  pageContext: {
    language: string;
    layout?: string;
    iframe?: boolean;
  };
}> = ({ children, location, pageContext }) => {
  const { t } = useTranslation();
  const { suffix } = titleTemplateParams(t);
  const { updateAnalyticsMetadata, updateAnalyticsPageData } = useAnalytics();

  useEffect(() => {
    // Vice Config
    const viceConfig = document.createElement('script');
    viceConfig.textContent = `vice={config:{viceAccountId:'282bb3ea-686a-4bee-b4ed-4497898cccb3',viceSiteId:'6b58403c-84ae-4986-baf5-5ba97ff91173'}}`;
    document.head.appendChild(viceConfig);

    // Vice Source
    const viceSource = document.createElement('script');
    viceSource.src =
      '//vice-prod.sdiapi.com/vice_loader/282bb3ea-686a-4bee-b4ed-4497898cccb3/6b58403c-84ae-4986-baf5-5ba97ff91173';
    document.head.appendChild(viceSource);

    return () => {
      document.head.removeChild(viceConfig);
      document.head.removeChild(viceSource);
    };
  }, []);

  updateAnalyticsPageData(pageContext);

  if (pageContext.layout === 'plain') {
    return <React.Fragment key="value">{children}</React.Fragment>;
  }
  if (pageContext.layout === 'domui') {
    return (
      <SitecoreLayout pageContext={pageContext} location={location}>
        {children}
      </SitecoreLayout>
    );
  }

  return (
    <ApolloCustomProvider>
      <FeatureToggles features={enabledFeatures}>
        <AppConfigProvider
          language={pageContext.language}
          path={delocalizedPath(location.pathname)}
        >
          <HelmetProvider>
            <Helmet
              onChangeClientState={updateAnalyticsMetadata}
              htmlAttributes={{ lang: pageContext.language }}
              titleTemplate={`%s ${suffix}`}
              defaultTitle={suffix}
            />
            <ImpersonationHeader />
            <div className="mainContainer min-h-full">
              {isEnabled(FEATURE_SITECORE_HEADER) ? (
                <SitecoreHeader />
              ) : (
                <Header />
              )}
              <Alerts />
              <div id={NOTIFICATIONS_WRAPPER_ID} />
              <main className="flex-1 tablet:pt-1">
                <ErrorBoundary>{children}</ErrorBoundary>
              </main>
              {isEnabled(FEATURE_SITECORE_FOOTER) ? (
                <SitecoreFooter />
              ) : (
                <Footer />
              )}
            </div>
          </HelmetProvider>
        </AppConfigProvider>
      </FeatureToggles>
    </ApolloCustomProvider>
  );
};

export default Layout;
